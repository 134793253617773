import Typography from '#components/shared/ui/Typography';
import { POSTFIXED_FEATURES } from '#constants/features.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import type { Feature } from '#types/FeatureHeroTypes';
import { getSearchParams } from '#utils/getSearchParams.ts';
import { type FC, useEffect, useState } from 'react';
import styles from './FeatureHeroTitleData.module.scss';
import clsx from 'clsx';

type Props = {
  feature: Feature;
  subtitle?: string;
  isBackgroundCheckFeature?: boolean;
  isDc?: boolean;
};

const FeatureHeroTitleData: FC<FeatureHeroCommonProps<Props>> = ({
  t,
  isBackgroundCheckFeature,
  subtitle,
  feature,
  isDc,
}) => {
  const lookups = t.lookups[feature as keyof typeof t.lookups];
  const defaultTitle = lookups[String('title') as keyof typeof lookups];
  const defaultDescription = lookups[String('description') as keyof typeof lookups];
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const isOffendersPage = feature === 'offenders';

  useEffect(() => {
    const titleText = getSearchParams('ptid');
    const descriptionText = getSearchParams('pdid');

    if (titleText) setTitle(titleText);
    if (descriptionText) setDescription(descriptionText);
  }, []);

  const withConditionalClasses = (...classes: string[]) => {
    const dcClass = isDc ? styles.dc : '';
    return [...classes, dcClass].join(' ').trim();
  };

  return (
    <>
      <Typography
        className={clsx(styles.title, isOffendersPage && styles.titleOffender)}
        component="h1"
        variant="h2"
        color="branded"
      >
        {title || defaultTitle}
      </Typography>
      {!title && POSTFIXED_FEATURES.includes(feature) && (
        <Typography
          className={withConditionalClasses(styles.title, styles.hide)}
          component="h1"
          variant="h2"
          color="branded"
        >
          {t.lookups.subTitle}
        </Typography>
      )}
      {subtitle && (
        <Typography
          className={withConditionalClasses(styles.subtitle)}
          component="h2"
          variant="h4"
          color="secondary"
        >
          {subtitle}
        </Typography>
      )}
      {!isBackgroundCheckFeature && !isDc && (
        <Typography
          className={clsx(styles.description, isOffendersPage && styles.descriptionOffender)}
          component="p"
          variant="body1"
          color="secondary"
        >
          {description || defaultDescription}
        </Typography>
      )}
    </>
  );
};

export default FeatureHeroTitleData;
